import { useEffect, useState } from "react";
import { fetchCity } from "sites-common/components/ui/City";

// const useCity = (cityId) => {
//   const [city, setCity] = useState({});
//   const available = !!city;
//   useEffect(() => {
//     if (available) {
//       return;
//     }
//     if (cityId && cityId !== "null") {
//       fetchCity(cityId).then(cityObj => {
//         setCity( { city: cityObj.city, state: cityObj.state, country: cityObj.country, sortKey: `${cityObj.country} ➡ ${cityObj.state} ➡ ${cityObj.name}` }) ;
//       })
//     } else {
//       setCity( { city: "", state: "", country: "", sortKey: "--- Missing City Name ---" } );
//     }
//   }, [cityId, setCity, available]);
//   return city;
// }
function useCities(cityIds) {
  const [cities, setCities] = useState({});

  const available = Object.keys(cities)
    .map((c) => `-${c}-`)
    .join("");

  useEffect(() => {
    cityIds.forEach((cityId) => {
      if (available.includes(`-${cityId}-`)) return;
      setCities((sc) => ({ ...sc, [cityId]: null }));
      if (cityId && cityId !== "null") {
        fetchCity(cityId).then((cityObj) => {
          setCities((sc) => ({
            ...sc,
            [cityId]: {
              city: cityObj.name,
              state: cityObj.state,
              country: cityObj.country,
              sortKey: `${cityObj.country} ➡ ${cityObj.state} ➡ ${cityObj.name}`,
            },
          }));
        });
      } else {
        setCities((sc) => ({
          ...sc,
          null: {
            city: "",
            state: "",
            country: "",
            sortKey: "--- Missing City Name ---",
          },
        }));
      }
    });
  }, [cityIds, setCities, available]);
  return cities;
}
export default useCities;
