/** @jsx jsx */
import { Badge, jsx } from "theme-ui";

import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import PropTypes from "prop-types";

import useMyBoolean from "sites-common/hooks/useMyBoolean";
import { IconButton, Stack, StackItem } from "office-ui-fabric-react";
import { useEffect, useMemo, useState } from "react";
import ZoneAndZCEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/ZoneAndZCEntity";
import { fetchGroup } from "sites-common/components/ui/SrcmGroup";
import useAshramFirebaseHooks from "./useAshramFirebaseHooks";

const formFields = [
  {
    name: "city",
    label: "City",
    type: "city",
  },
  {
    name: "center",
    label: "Center",
    type: "srcm_center_id",
  },
  {
    name: "inactive",
    label: "Inactive",
    type: "toggle",
  },
];

// const RenderCityName = React.memo(({ cityId }) => {
//   const cities = useCities([cityId]);

//   return (
//     <span title={get(cities, [cityId, "sortKey"], "-Missing-")}>
//       {get(cities, [cityId, "sortKey"], "--")}

//     </span>
//   );
// });

const AMCAshramConfig = ({ centerKey, centerRecord }) => {
  const [editMode, { toggle }] = useMyBoolean(false);

  const { saveCenter } = useAshramFirebaseHooks();
  const onSubmit = useMemo(
    () => saveCenter(centerKey),
    [saveCenter, centerKey]
  );

  const [centerDetail, setCenterDetail] = useState("");

  useEffect(() => {
    if (centerRecord.center) {
      fetchGroup(centerRecord.center).then((r) => setCenterDetail(r.name));
    }
  }, [centerRecord.center]);

  return (
    <div>
      {!editMode && (
        <div>
          {centerRecord.inactive && <Badge>inactive</Badge>}

          <Stack horizontal horizontalAlign="space-between">
            <StackItem>
              <ZoneAndZCEntity
                centerId={centerRecord.center}
                display={centerDetail}
              />
            </StackItem>

            <StackItem>
              <IconButton
                iconProps={{ iconName: "Edit" }}
                disabled={editMode}
                title="Edit"
                onClick={toggle}
              />
            </StackItem>
          </Stack>
        </div>
      )}
      {editMode && (
        <div sx={{ bg: "#DDDDDD", p: 2 }}>
          <DynamicForm
            formFields={formFields}
            defaultValues={centerRecord}
            onSubmit={onSubmit}
            onDiscard={toggle}
          />{" "}
        </div>
      )}
    </div>
  );
};

AMCAshramConfig.propTypes = {
  centerKey: PropTypes.string.isRequired,
  centerRecord: PropTypes.shape({
    members: PropTypes.shape({}),
    center: PropTypes.string,
    inactive: PropTypes.bool,
  }).isRequired,
};

export default AMCAshramConfig;
