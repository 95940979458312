import SearchModel from "./SearchModel";

const SearchPositions = ({ srcmGroup, allResultsRender }) =>
  SearchModel({
    model: `/groups/${srcmGroup}/positions`,
    allResultsRender,
    client: "preceptorCandidateAppClient",
  });

export default SearchPositions;
