import { useCallback, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { get } from "sites-common/utils/lodash";

const collection_admin = `AMCMembers`; /// // rename this to AMCMembers
const storeAs = "amc";

function useAshramFirebaseHooks() {
  const firestore = useFirestore();

  const dbRecords = useSelector((state) =>
    get(state.firestore.ordered, [storeAs], [])
  );

  const isLoading = dbRecords === undefined || dbRecords === null;
  const centersList = Array.isArray(dbRecords) ? dbRecords[0]?.centers : [];
  const positionsList = Array.isArray(dbRecords) ? dbRecords[0]?.positions : [];

  useEffect(() => {
    const listenerSettings1 = {
      collection: collection_admin,
      doc: storeAs,
      storeAs,
    };
    const listenerSettings = { ...listenerSettings1 };
    firestore.setListener(listenerSettings);
    return function cleanup() {
      firestore.unsetListener(listenerSettings);
    };
  }, [firestore]);

  const onSave = useCallback(
    (data, sCb, fCb) => {
      firestore
        .set(
          `${collection_admin}/${storeAs}`,
          {
            ...data,
          },
          { merge: true }
        )
        .then(() => {
          sCb();
        })
        .catch((e) => {
          fCb(e.toString());
        });
    },
    [firestore]
  );

  const saveCenter = useCallback(
    (key) => (vdict, sCb, fCb) => {
      const data = { centers: { [key]: vdict } };
      onSave(data, sCb, fCb);
    },
    [onSave]
  );

  const saveCenterMembers = useCallback(
    (center) => (vdict, sCb, fCb) => {
      const data = { members: vdict };
      saveCenter(center)(data, sCb, fCb);
    },
    [saveCenter]
  );

  return {
    onSave,
    saveCenter,
    saveCenterMembers,
    isLoading,
    centersList,
    positionsList,
  };
}

export default useAshramFirebaseHooks;
