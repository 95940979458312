/** @jsx jsx */
import { jsx } from "theme-ui";
import { containerStyles } from "sites-common/utils/fabricStyles";
import PropTypes from "prop-types";
import Layout from "../components/SiteLayout";

const RootWrapper = require("sites-common/gatsby-utils/WrapRoot").default;

const MobileLayout = ({ bg, ...rprops }) => (
  <RootWrapper
    element={
      <Layout>
        <div
          sx={{
            ...containerStyles.fullPage,
            bg,
          }}
        >
          <div {...rprops} />
        </div>
      </Layout>
    }
  />
);

MobileLayout.propTypes = {
  bg: PropTypes.string,
};

MobileLayout.defaultProps = {
  bg: "background",
};

export default MobileLayout;
