/** @jsx jsx */
import { jsx } from "theme-ui";
import { useQueryParam, StringParam } from "use-query-params";
import { Stack } from "office-ui-fabric-react";
import DefaultLayout from "../../layouts/dlw-fullpage";
import AMCDBRecord from "../../components/AMC/dbRecord";

const Index = (/* { data } */) => {
  const [initialFilter] = useQueryParam("q", StringParam);

  return (
    <DefaultLayout>
      <Stack horizontalAlign="center">
        <h2> Ashram Management </h2>
      </Stack>

      <AMCDBRecord initialFilter={initialFilter || ""} />
    </DefaultLayout>
  );
};

export default Index;
