/** @jsx jsx */
import { Spinner, jsx } from "theme-ui";
import PropTypes from "prop-types";
import DynamicForm, {
  DynamicField,
} from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";

import { useCallback, useState } from "react";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import { DefaultButton, Stack, StackItem } from "office-ui-fabric-react";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import useCities from "gatsby-plugin-hfn-profile/components/SrcmModels/useCities";
import { get } from "sites-common/utils/lodash";
import Settings from "./Settings";
import AMCCommiteeMembers from "./commiteeMembers";
import AMCAshramConfig from "./ashramConfig";
import useAshramFirebaseHooks from "./useAshramFirebaseHooks";

const CreateAshram = () => {
  const [edit, { setTrue, setFalse }] = useMyBoolean(false);

  const formFields = [
    {
      name: "key",
      label: "Ashram (Unique Name)",
      type: "text",
    },
  ];

  const { saveCenter, centersList } = useAshramFirebaseHooks();

  const formValidator = useCallback(
    (r) => {
      if (!!centersList && r.key in centersList) {
        return `${r.key}: Ashram Name already exists!`;
      }
      return false;
    },
    [centersList]
  );

  const onSubmit = useCallback(
    (vdata, sCb, fCb) => {
      saveCenter(vdata.key)(vdata, sCb, fCb);
    },
    [saveCenter]
  );
  if (!edit) {
    return (
      <DefaultButton
        styles={buttonStyles.blueLight}
        text="Add New Ashram"
        onClick={setTrue}
      />
    );
  }

  return (
    <div sx={{ bg: "lightgrey", p: 2 }}>
      <DynamicForm
        formFields={formFields}
        formValidator={formValidator}
        defaultValues={{}}
        onSubmit={onSubmit}
        onDiscard={setFalse}
      />
    </div>
  );
};

const AMCDBRecord = ({ initialFilter }) => {
  const { isLoading, centersList, saveCenterMembers } =
    useAshramFirebaseHooks();
  const centerRecords = Object.entries(centersList || {});
  const cityIds = centerRecords.map(([, r]) => r.city).filter((a) => a);
  const cities = useCities(cityIds);

  const [filter, setFilter] = useState(initialFilter);

  const lfilter = filter.toLowerCase();
  const getCitySortKey = useCallback(
    (cityId) => get(cities, [cityId, "sortKey"], "-Missing City-"),
    [cities]
  );
  const filteredCenterRecords = centerRecords.filter(
    ([centerKey, centerRecord]) =>
      lfilter
        ? centerKey.toLowerCase().includes(lfilter) ||
          getCitySortKey(centerRecord.city).toLowerCase().includes(lfilter)
        : true
  );

  return (
    <div>
      <div>
        {isLoading && <Spinner />}
        <Stack horizontal tokens={{ childrenGap: 12 }}>
          <StackItem grow sx={{ px: 3 }}>
            <DynamicField
              name="filter"
              onChange={setFilter}
              value={filter}
              label=""
              props={{ required: false, placeholder: "Search Ashrams ..." }}
            />
          </StackItem>
          <StackItem>
            <CreateAshram />
          </StackItem>
          <StackItem>
            <Settings />
          </StackItem>
        </Stack>
        <Stack>
          <i sx={{ px: 3, color: "darkblue", fontSize: "0.9em" }}>
            {" "}
            {filteredCenterRecords.length} Ashrams
          </i>
        </Stack>
        {/* {Array.isArray(centerRecords) && centerRecords.length === 0 && <div>No Centers Available</div>} */}

        {filteredCenterRecords.length > 0 && (
          <Stack horizontal wrap tokens={{ childrenGap: 8, padding: 8 }}>
            {filteredCenterRecords
              .sort(([a], [b]) => a > b)
              .map(([centerKey, centerRecord]) => (
                <StackItem
                  sx={{
                    boxShadow: "0px 3px 6px #00000029",
                    opacity: 1,
                    borderRadius: "2px",
                    borderColor: "background",
                    backgroundColor: "background",
                    p: 2,
                  }}
                >
                  {getCitySortKey(centerRecord.city)}
                  <h2>{centerKey}</h2>

                  <AMCAshramConfig
                    centerKey={centerKey}
                    centerRecord={centerRecord}
                  />
                  <AMCCommiteeMembers
                    key={centerKey}
                    centerName={centerKey}
                    members={centerRecord?.members || {}}
                    saveMembers={saveCenterMembers(centerKey)}
                  />
                </StackItem>
              ))}
          </Stack>
        )}
      </div>
    </div>
  );
};

AMCDBRecord.propTypes = {
  initialFilter: PropTypes.string,
};
AMCDBRecord.defaultProps = {
  initialFilter: "",
};
export default AMCDBRecord;
