/** @jsx jsx */
import { jsx } from "theme-ui";

import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";

import useMyBoolean from "sites-common/hooks/useMyBoolean";
import { DefaultButton, Panel } from "office-ui-fabric-react";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import useAshramFirebaseHooks from "./useAshramFirebaseHooks";

const formFields = [
  {
    name: "positions",
    label: "Positions",
    type: "string",
    listField: true,
  },
];

const Settings = () => {
  const [editMode, { toggle }] = useMyBoolean(false);

  const { onSave, positionsList = [] } = useAshramFirebaseHooks();

  return (
    <div>
      <DefaultButton
        styles={buttonStyles.dangerLight}
        iconProps={{ iconName: "Settings" }}
        text="Settings"
        onClick={toggle}
      />
      <Panel headerText="Settings" isOpen={editMode} onDismiss={toggle}>
        <DynamicForm
          formFields={formFields}
          defaultValues={{ positions: positionsList }}
          onSubmit={onSave}
          onDiscard={toggle}
        />
      </Panel>
    </div>
  );
};

export default Settings;
