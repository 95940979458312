/** @jsx jsx */
import { Spinner, jsx } from "theme-ui";
import React, { useCallback, useState } from "react";
import DynamicForm, {
  DynamicField,
} from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";

import {
  ActionButton,
  MessageBar,
  Stack,
  StackItem,
} from "office-ui-fabric-react";
import PropTypes from "prop-types";
import copyKeys from "sites-common/utils/copyKeys";
import PeopleSelector from "../PeopleSelector";
import AbhyasiEntity from "../SrcmEntities/AbhyasiEntity";
import ConfirmButton from "../ConfirmButton";

const printValues = (l, v) => <i>{v}&nbsp;</i>;
const PeopleList = ({
  people,
  savePeople,
  peopleSelectorProps,
  editableFormFields: formFields,
  printFormFields,
  editFieldsVertical,
  readOnly,
  copyAimsFields /* name, id, and uid get already populated, no need to give them */,
}) => {
  const [editPerson, setEditPerson] = useState(null);
  const [status, setStatus] = useState(null);

  const onSuccessSave = useCallback(() => {
    setStatus(null);
  }, [setStatus]);

  const onFailure = useCallback(
    (e) => {
      setStatus(e);
    },
    [setStatus]
  );

  const addNew = useCallback(
    (pdict) => {
      const pdictnew = Object.fromEntries(
        Object.entries(pdict).map(([k, v]) => {
          const x = copyKeys(
            v,
            [...copyAimsFields, "name", "user_firebase_uid", "id"],
            {
              user_firebase_uid: "uid",
            }
          );
          return [k, x];
        })
      );
      savePeople(pdictnew, onSuccessSave, onFailure);
    },
    [savePeople, onSuccessSave, onFailure, copyAimsFields]
  );

  const updateRec = useCallback(
    (key, vals, scb, fcb) => {
      savePeople({ [key]: vals }, scb, fcb);
    },
    [savePeople]
  );

  const deletePerson = useCallback(
    (key) => {
      savePeople({ [key]: { id: "deleted" } }, onSuccessSave, onFailure);
    },
    [savePeople, onSuccessSave, onFailure]
  );

  return (
    <div>
      <table width="100%">
        <tbody>
          {Object.entries(people)
            .filter(([, rec]) => rec.id !== "deleted")
            .map(([key, rec], idx) => (
              <React.Fragment>
                <tr>
                  <td>{idx + 1}.</td>
                  <td>
                    <AbhyasiEntity id={key} display={rec.name} />
                    <div sx={{ pl: 4, color: "darkblue", fontSize: "0.8em" }}>
                      {" "}
                      {printFormFields && printFormFields.length > 0 && (
                        <DynamicForm
                          formFields={printFormFields}
                          defaultValues={rec}
                          printOnly={printValues}
                        />
                      )}
                    </div>
                  </td>
                  {!readOnly && (
                    <td>
                      <ConfirmButton
                        title={`Removing ${rec.name} from Center`}
                        onClick={() => deletePerson(key)}
                      >
                        <ActionButton
                          disabled={editPerson}
                          iconProps={{ iconName: "Trash" }}
                          text={editFieldsVertical ? "" : "delete"}
                          title={`Remove ${rec.name} from Center`}
                        />
                      </ConfirmButton>
                    </td>
                  )}
                </tr>
                {formFields && formFields.length > 0 && (
                  <tr>
                    <td sx={{ borderBottom: "solid 1px black" }} />
                    <td sx={{ px: 3, pb: 2, borderBottom: "solid 1px black" }}>
                      <React.Fragment>
                        {editPerson && key === editPerson ? (
                          <div sx={{ backgroundColor: "#DEDEDE", p: 2 }}>
                            <DynamicForm
                              formFields={formFields}
                              defaultValues={people[editPerson]}
                              onSubmit={(vals, scb, fcb) =>
                                updateRec(editPerson, vals, scb, fcb)
                              }
                              onDiscard={() => setEditPerson(null)}
                            />
                          </div>
                        ) : (
                          <Stack
                            horizontal={!editFieldsVertical}
                            wrap
                            tokens={{ childrenGap: 10 }}
                          >
                            {formFields.map((f) => (
                              <StackItem>
                                <DynamicField
                                  {...f}
                                  props={{ ...f.props, disabled: true }}
                                  value={rec[f.name]}
                                />
                              </StackItem>
                            ))}
                          </Stack>
                        )}
                      </React.Fragment>
                    </td>
                    {!readOnly && (
                      <td sx={{ borderBottom: "solid 1px black" }}>
                        <ActionButton
                          disabled={editPerson}
                          iconProps={{ iconName: "Edit" }}
                          title={`Edit details for ${rec.name} `}
                          text={editFieldsVertical ? "" : "edit"}
                          onClick={() => setEditPerson(key)}
                        />
                      </td>
                    )}
                  </tr>
                )}
              </React.Fragment>
            ))}
        </tbody>
      </table>

      {!readOnly && (
        <div sx={{ m: 3 }}>
          <PeopleSelector {...peopleSelectorProps} onSelectionDone={addNew} />

          <div>
            {status === "loading" && <Spinner />}
            {!!status && status !== "loading" && (
              <MessageBar>{status}</MessageBar>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

PeopleList.propTypes = {
  people: PropTypes.shape({}).isRequired,
  savePeople: PropTypes.func.isRequired,
  peopleSelectorProps: PropTypes.shape({}).isRequired,
  editableFormFields: PropTypes.arrayOf(PropTypes.shape({})),
  printFormFields: PropTypes.arrayOf(PropTypes.shape({})),
  editFieldsVertical: PropTypes.bool,
  readOnly: PropTypes.bool,
  copyAimsFields: PropTypes.arrayOf(PropTypes.string),
};
PeopleList.defaultProps = {
  printFormFields: [],
  editableFormFields: [],
  editFieldsVertical: false,
  readOnly: false,
  copyAimsFields: [],
};
export default PeopleList;
