/** @jsx jsx */
import { jsx } from "theme-ui";

import { buttonStyles } from "sites-common/utils/fabricStyles";
import PropTypes from "prop-types";
import PeopleList from "gatsby-plugin-hfn-profile/components/PeopleList";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import { DefaultButton, Panel, PanelType } from "office-ui-fabric-react";
import useAshramFirebaseHooks from "./useAshramFirebaseHooks";

const AMCCommiteeMembers = ({ centerName, members, saveMembers }) => {
  const [details, { toggle: toggleDetails }] = useMyBoolean(false);
  const { positionsList } = useAshramFirebaseHooks();

  return (
    <div>
      <h4>AMC Members</h4>

      {Object.keys(members).length === 0 && <div>--No Members Added--</div>}
      {details && (
        <Panel
          isOpen
          onDismiss={toggleDetails}
          type={PanelType.large}
          headerText={centerName}
        >
          <PeopleList
            people={members}
            savePeople={saveMembers}
            peopleSelectorProps={{
              searchLabel: "Person's ID or Email or Mobile",
              showMyself: false,
              btnProps: {
                styles: buttonStyles.blueLight,
                text: "Add Members",
                iconProps: { iconName: "Group" },
              },
              // searchModes: ["advanced"],
            }}
            copyAimsFields={["ref", "mobile", "email", "city"]} // name, id and uid get autopopulated, no need to give them
            editableFormFields={[
              { name: "mobile", label: "Mobile", type: "phone" },
              { name: "email", label: "Email", type: "email" },
              {
                name: "position",
                label: "Position",
                type: "select",
                options: positionsList,
              },
            ]}
          />
        </Panel>
      )}
      <PeopleList
        people={members}
        savePeople={saveMembers}
        printFormFields={[
          {
            name: "position",
            label: "Position",
            type: "select",
            options: positionsList,
          },
        ]}
        readOnly
      />
      <DefaultButton
        sx={{ my: 3 }}
        onClick={toggleDetails}
        text="View / Change Member Details"
        iconProps={{ iconName: "Group" }}
      />
    </div>
  );
};

AMCCommiteeMembers.propTypes = {
  centerName: PropTypes.string.isRequired,
  members: PropTypes.shape({}).isRequired,
  saveMembers: PropTypes.func.isRequired,
};

export default AMCCommiteeMembers;
