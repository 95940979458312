/** @jsx jsx */
import { jsx, Alert } from "theme-ui";
import { Spinner as SpinSmall } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import { doFormatTitle } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { useSrcmApi } from "../../fetch-srcm-api";

function SearchId({ id, model, render, client }) {
  const apiParams = { api: `/api/v2/${model}/${id}/`, client };

  const { isFetching, data, errorMessage } = useSrcmApi(
    `${model}${id}`,
    apiParams
  );
  if (isFetching) {
    return <SpinSmall />;
  }
  if (errorMessage) {
    return <Alert>{errorMessage}</Alert>;
  }
  if (data === null) {
    return <SpinSmall />;
  }
  return <span>{render ? render(data) : doFormatTitle(data.name)}</span>;
}

SearchId.propTypes = {
  id: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired,
  render: PropTypes.func,
  client: PropTypes.string,
};

SearchId.defaultProps = {
  render: null,
  client: "baseClient",
};

export default SearchId;
