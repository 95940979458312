/** @jsx jsx */
import { jsx } from "theme-ui";

import { ActionButton, HoverCard, HoverCardType } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import RenderZoneDetailsForCenter from "./Render/RenderZoneDetailsForCenter";

const ZoneAndZCEntity = ({ centerId, display }) => {
  const plainCardProps = {
    onRenderPlainCard: RenderZoneDetailsForCenter,
    renderData: { centerId, title: display || centerId },
  };

  return (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick
      type={HoverCardType.plain}
    >
      <ActionButton
        iconProps={{ iconName: "Badge" }}
        text={display || centerId}
      />
    </HoverCard>
  );
};

ZoneAndZCEntity.propTypes = {
  centerId: PropTypes.number.isRequired,
  display: PropTypes.string,
};

ZoneAndZCEntity.defaultProps = {
  display: "",
};

export default ZoneAndZCEntity;
